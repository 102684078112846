import React from 'react';
import Home from './pages/Home';
import Error from "./pages/Error";
import Forbidden from "./pages/Forbidden";
import NotFound from "./pages/NotFound";

import { Route } from 'react-router-dom';
import { isUserLoggedIn } from "./base/OAuth";
import Login from "./pages/user/Login";
import Lock from "./pages/user/Lock";
import UserList from "./pages/admin/users/UserList";
import Register from "./pages/user/Register";
import CorStates from "./pages/corStates/CorStates";
import VendorsDataBase from "./pages/vendorsDataBase/VendorsDataBase";
import CorVendors from "./pages/corVendors/CorVendors";
import DttCountries from "./pages/dttCountries/DttCountries";
import VatProxies from "./pages/vatProxies/VatProxies";
import CreditPackages from "./pages/creditPackages/CreditPackages";
import ContactUs from "./pages/contactUs/ContactUs";
import AdminLogin from "./pages/admin/login/AdminLogin";
import AdminCorStates from "./pages/admin/corStates/AdminCorStates";
import AdminCorVendors from "./pages/admin/corVendors/AdminCorVendors";
import AdminDttCountries from "./pages/admin/dttCountries/AdminDttCountries";
import AdminVatProxies from "./pages/admin/vatProxies/AdminVatProxies";
import AdminCreditPackages from "./pages/admin/creditPackages/AdminCreditPackages";
import LawsAndRegulations from "./pages/lawsAndRegulations/LawsAndRegulations";
import SingleBlog from "./pages/singleBlog/SingleBlog";
import AboutUs from "./pages/aboutUs/AboutUs";
import TermsOfServices from "./pages/termsAndConditions/TermsOfServices";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import GuestSearch from "./pages/admin/guestSearch/GuestSearch";
import AdminLawsAndRegulations from "./pages/admin/lawsAndRegulations/AdminLawsAndRegulations";
import AdminVendorsDataBase from "./pages/admin/vendorsDataBase/AdminVendorsDataBase";
import MyProfile from "./pages/user/MyProfile";
import PaymentProcessApproval from "./pages/admin/creditPackages/PaymentProcessApproval";
import News from "./pages/news/News";

let ROUTES = {
    Home: {
        path: '/',
        component: <Home/>,
        auth: false
    },
    Error: {
        path: '/error',
        component: <Error/>,
        auth: false
    },
    Forbidden: {
        path: '/forbidden',
        component: <Forbidden/>,
        auth: false
    },
    NotFound: {
        path: '/not-found',
        component: <NotFound/>,
        auth: false
    },
    Login: {
        path: '/login',
        component: <Login/>,
        auth: false
    },
    AdminLogin: {
        path: '/admin/login',
        component: <AdminLogin/>,
        auth: false
    },
    Lock: {
        path: '/lock',
        component: <Lock/>,
        auth: false
    },
    UserList: {
        path: '/users',
        component: <UserList showFilter={ false }/>,
        auth: true
    },
    Register: {
        path: '/register',
        component: <Register />,
        auth: false
    },
    CorStates: {
        path: '/cor-states',
        component: <CorStates />,
        auth: false
    },
    VendorsDataBase: {
        path: '/vendors-data-base',
        component: <VendorsDataBase />,
        auth: false
    },
    CorVendors: {
        path: '/cor-vendors',
        component: <CorVendors />,
        auth: false
    },
    DttCountries: {
        path: '/dtt-countries',
        component: <DttCountries />,
        auth: false
    },
    VatProxies: {
        path: '/vat-proxies',
        component: <VatProxies />,
        auth: false
    },
    CreditPackages: {
        path: '/credit-packages',
        component: <CreditPackages />,
        auth: false
    },
    ContactUs: {
        path: '/contact-us',
        component: <ContactUs />,
        auth: false
    },
    AdminCorStates: {
        path: '/admin/cor-states',
        component: <AdminCorStates />,
        auth: false
    },
    AdminCorVendors: {
        path: '/admin/cor-vendors',
        component: <AdminCorVendors />,
        auth: false
    },
    AdminDttCountries: {
        path: '/admin/dtt-countries',
        component: <AdminDttCountries />,
        auth: false
    },
    AdminVatProxies: {
        path: '/admin/vat-proxies',
        component: <AdminVatProxies />,
        auth: false
    },
    AdminCreditPackages: {
        path: '/admin/credit-packages',
        component: <AdminCreditPackages />,
        auth: false
    },
    LawsAndRegulations: {
        path: '/laws-and-regulations',
        component: <LawsAndRegulations />,
        auth: false
    },
    SingleBlog: {
        path: '/single-blog',
        component: <SingleBlog />,
        auth: false
    },
    AboutUs: {
        path: '/about-us',
        component: <AboutUs />,
        auth: false
    },
    TermsOfServices: {
        path: '/terms-of-services',
        component: <TermsOfServices />,
        auth: false
    },
    PrivacyPolicy: {
        path: '/privacy-policy',
        component: <PrivacyPolicy />,
        auth: false
    },
    GuestSearch: {
        path: '/admin/guest-search',
        component: <GuestSearch />,
        auth: false
    },
    AdminLawsAndRegulations: {
        path: '/admin/laws-and-regulations',
        component: <AdminLawsAndRegulations />,
        auth: false
    },
    AdminVendorsDataBase: {
        path: '/admin/vendors-data-base',
        component: <AdminVendorsDataBase />,
        auth: false
    },
    ProcessPaymentApproval: {
        path: '/admin/payment-process-approval',
        component: <PaymentProcessApproval />,
        auth: false
    },
    MyProfile: {
        path: '/my-profile',
        component: <MyProfile />,
        auth: false
    },
    News: {
        path: '/news',
        component: <News />,
        auth: false
    },
};

export default ROUTES;

function getRoute(path) {

    for(const [key, value] of Object.entries(ROUTES)) {

        if(value.path === path) {
            return value;
        }
    }

    return null;
}

export function checkPath(path) {

    let pathObject = getRoute(path);

    if(!pathObject) {
        return true;
    }

    if(pathObject.auth) {
        return !isUserLoggedIn();
    }

    return false;
}

export function getRoutes() {

    let result = [];

    for(const [key, value] of Object.entries(ROUTES)) {

        result.push(
            <Route key={ 'route-' + result.length } exact path={ value.path } render={() => (
                value.component
            )}/>
        )
    }

    return result;
}