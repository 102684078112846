import React, {Component} from 'react'
import Grid from '@material-ui/core/Grid';
import Page from "../common/Page";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import {withRouter} from "react-router-dom";
import connect from "react-redux/es/connect/connect";
import SearchComponent from "../components/searchComponent/SearchComponent";
import Services from "../components/services/Services";
import LawsAndRegulations from "../components/lawsAndRegulations/LawsAndRegulations";
import News from "../components/news/News";
import Navigation from "../components/admin/Navigation";
import AdminMobileNavigation from "../components/admin/AdminMobileNavigation";

class Home extends Page {

    constructor(props) {
        super(props);

        this.state = {
            data: {}
        }

        this.props.changeFullScreen(false);
    }

    render() {
        return (
            <div id={'main-page'}>
                <Grid container spacing={24}>
                    {/*<Navigation />*/}
                    {/*<AdminMobileNavigation />*/}
                    <SearchComponent />
                    <Services />
                    <LawsAndRegulations />
                    <News />
                </Grid>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeFullScreen: Actions.changeFullScreen
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

// export default withSnackbar(withRouter(connect(mapStateToProps, mapDispatchToProps)(Home)));
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
