import React, {Component} from 'react'
import {Link, withRouter} from "react-router-dom";
import {bindActionCreators} from "redux";
import * as Actions from "../actions/Actions";
import connect from "react-redux/es/connect/connect";
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LockIcon from '@material-ui/icons/Lock';
import {Button, ListItemIcon, ListItemText, Menu, MenuItem} from "@material-ui/core";
import strings from "../localization";
import {lock, logout} from "../base/OAuth";

class Header extends Component {

    constructor(props) {
        super(props);

        this.state = {
            anchorEl: null,
            services: false,
            affiliates: false,
            how: false,
            language: false,
            data: {}
        }
    }

    /** HANDLERS **/

    handleMenuClick(event, menu) {
        this.setState({
            [menu]: event.currentTarget
        });
    }

    handleMenuClose(menu) {
        this.setState({
            [menu]: null
        });
    }

    logout() {
        logout();
        this.props.logout();
        this.props.history.push('/');
    }

    lock() {
        lock();
        this.props.history.push('/my-profile');
    }

    changeLanguage(){}

    renderServicesMenu(){
        return(
            <Menu
                id='services-menu'
                anchorEl={ this.state.services }
                open={ Boolean(this.state.services) }
                onClose={ () => this.handleMenuClose('services') }
                MenuListProps={{ onMouseLeave: () => this.handleMenuClose('services') }}
                getContentAnchorEl={null}
                // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                // transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MenuItem>
                    <a href={'#'}>Invoicing with auto tax solution - taxvoice</a>
                </MenuItem>

                <MenuItem>
                    <a href={'#'}>Global tax compliance</a>
                </MenuItem>

                <MenuItem>
                    <a href={'#'}>International tax advisory</a>
                </MenuItem>

                <MenuItem>
                    <a href={'#'}>Risk assesment</a>
                </MenuItem>

                <MenuItem>
                    <a href={'#'}>Tax planing and decisioning</a>
                </MenuItem>

                {/*<MenuItem>*/}
                {/*    <a href={'/cor-states'}>{ strings.services.corStates }</a>*/}
                {/*</MenuItem>*/}

                {/*<MenuItem>*/}
                {/*    <a href={'/cor-vendors'}>{ strings.services.corVendors }</a>*/}
                {/*</MenuItem>*/}

                {/*<MenuItem>*/}
                {/*    <a href={'/vendors-data-base'}>{ strings.services.vendorsDataBase }</a>*/}
                {/*</MenuItem>*/}

                {/*<MenuItem>*/}
                {/*    <a href={'/dtt-countries'}>{ strings.services.dttCountries }</a>*/}
                {/*</MenuItem>*/}

                {/*<MenuItem>*/}
                {/*    <a href={'/vat-proxies'}>{ strings.services.vatProxies }</a>*/}
                {/*</MenuItem>*/}
            </Menu>
        );
    }

    renderLanguagesMenu(){
        return(
            <Menu
                id='language-menu'
                anchorEl={ this.state.language }
                open={ Boolean(this.state.language) }
                onClose={ () => this.handleMenuClose('language') }
                MenuListProps={{ onMouseLeave: () => this.handleMenuClose('language') }}
                getContentAnchorEl={null}
                // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                // transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MenuItem onClick={ () => this.changeLanguage() }>
                    { strings.languages.en }
                </MenuItem>
                <MenuItem onClick={ () => this.changeLanguage() }>
                    { strings.languages.srb }
                </MenuItem>
            </Menu>
        );
    }

    renderMyProfileMenu(){
        return(
            <Menu
                id='person-menu'
                anchorEl={ this.state.anchorEl }
                open={ Boolean(this.state.anchorEl) }
                onClose={ () => this.handleMenuClose('anchorEl') }
                MenuListProps={{ onMouseLeave: () => this.handleMenuClose('anchorEl') }}
                getContentAnchorEl={null}
                // anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                // transformOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <MenuItem onClick={ () => this.lock() }>
                    <ListItemIcon>
                        {/*<LockIcon/>*/}
                        <img src={'/images/edit.svg'} />
                    </ListItemIcon>
                    <ListItemText inset primary={ strings.myProfile.editProfile }/>
                </MenuItem>
                <MenuItem onClick={ () => this.logout() }>
                    <ListItemIcon>
                        <ExitToAppIcon/>
                    </ListItemIcon>
                    <ListItemText inset primary={ strings.header.logout }/>
                </MenuItem>
            </Menu>
        );
    }

    render() {
        return (
            <div id='header'>
                <div className='left'>
                    <a href={'/'}><img src={'/images/logo.png'}/></a>
                    {
                        !this.props.user
                        ?
                            <React.Fragment>
                                <div className={'service-dropdown-wrapper'}
                                     onMouseEnter={ (event) => this.handleMenuClick(event, 'how') }
                                     onMouseLeave={() => this.handleMenuClose('how') }
                                >
                                    <div className={'service-parent-link'}><p>How it works</p><img src={'images/Polygon1.svg'} /></div>
                                    <Menu
                                        id='services-menu'
                                        anchorEl={ this.state.how }
                                        open={ Boolean(this.state.how) }
                                        onClose={ () => this.handleMenuClose('how') }
                                        MenuListProps={{ onMouseLeave: () => this.handleMenuClose('how') }}
                                        getContentAnchorEl={null}
                                    >
                                        <MenuItem>
                                            <a href={'#'}>As a buyer</a>
                                        </MenuItem>

                                        <MenuItem>
                                            <a href={'#'}>As a seller</a>
                                        </MenuItem>

                                    </Menu>
                                </div>
                                <div className={'service-dropdown-wrapper'}
                                     onMouseEnter={ (event) => this.handleMenuClick(event, 'services') }
                                     onMouseLeave={() => this.handleMenuClose('services') }
                                >
                                    <div className={'service-parent-link'}><p>{ strings.header.services }</p><img src={'images/Polygon1.svg'} /></div>
                                    {
                                        this.renderServicesMenu()
                                    }
                                </div>
                                <div className={'service-dropdown-wrapper'}
                                     onMouseEnter={ (event) => this.handleMenuClick(event, 'affiliates') }
                                     onMouseLeave={() => this.handleMenuClose('affiliates') }
                                >
                                    <div className={'service-parent-link'}><p>Affiliates</p><img src={'images/Polygon1.svg'} /></div>
                                    <Menu
                                        id='services-menu'
                                        anchorEl={ this.state.affiliates }
                                        open={ Boolean(this.state.affiliates) }
                                        onClose={ () => this.handleMenuClose('affiliates') }
                                        MenuListProps={{ onMouseLeave: () => this.handleMenuClose('affiliates') }}
                                        getContentAnchorEl={null}
                                    >
                                        <MenuItem>
                                            <a href={'#'}>Cointries</a>
                                        </MenuItem>

                                        <MenuItem>
                                            <a href={'#'}>How it works</a>
                                        </MenuItem>

                                    </Menu>
                                </div>
                                <a href={'/credit-packages'}><p>{ strings.header.credits }</p></a>
                                <a href={'/news'}><p>{ strings.header.news }</p></a>
                                <a href={'/contact-us'}><p>{ strings.header.contactUs }</p></a>
                                <div className={'language-dropdown-wrapper'}
                                     onMouseEnter={ (event) => this.handleMenuClick(event, 'language') }
                                     onMouseLeave={() => this.handleMenuClose('language') }
                                >
                                    <div className={'language-parent-link'}><p>{ strings.header.en }</p><img src={'images/Polygon1.svg'} /></div>
                                    {
                                        this.renderLanguagesMenu()
                                    }
                                </div>
                            </React.Fragment>
                        :
                        ""
                    }
                </div>
                <div className='right'>
                    {/*<IconButton*/}
                    {/*    size='small'*/}
                    {/*    aria-owns={ this.state.anchorEl ? 'person-menu' : undefined }*/}
                    {/*    aria-haspopup="true"*/}
                    {/*>*/}
                        {
                            this.props.user
                            ?
                            <div className={'profile-wrapper'}
                                 onMouseMove={ (event) => this.handleMenuClick(event, 'anchorEl') }
                                 onMouseLeave={() => this.handleMenuClose('anchorEl') }
                            >
                                <p className={'my-profile'}>{ strings.header.myProfile }</p>
                                <img src={'images/Polygon1.svg'} />
                                {
                                    this.renderMyProfileMenu()
                                }
                            </div>
                            :
                            <div className={'profile-wrapper'}>
                                <p className={'create-account'} onClick={() => {
                                    this.props.history.push('/register');
                                }
                                }>{ strings.header.createAccount }</p>
                                <Button variant="contained" color="primary" onClick={() => {
                                    this.props.history.push('/login');
                                }}>
                                    { strings.header.login }
                                </Button>
                            </div>
                        }
                    {/*</IconButton>*/}
                </div>
            </div>
        );
    }
}

function mapDispatchToProps(dispatch)
{
    return bindActionCreators({
        changeMenuState: Actions.changeMenuState,
        logout: Actions.logout,
    }, dispatch);
}

function mapStateToProps({ menuReducers, authReducers })
{
    return { menu: menuReducers, user: authReducers.user };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
